import React from "react";

const Logo = ({ width = 131, height = 40, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 131 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M48.7373 14.7507C48.1223 14.7507 47.5586 14.3956 47.3023 13.7871C46.1236 10.896 44.1249 8.41085 41.5624 6.58497C38.6924 4.55622 35.3611 3.49113 31.8249 3.49113C27.5198 3.49113 23.4199 5.11413 20.2936 8.05582C19.3199 8.96876 18.4486 10.0339 17.6799 11.2004C17.2186 11.9105 16.2961 12.1133 15.5786 11.6568C14.8611 11.2004 14.6561 10.2874 15.1174 9.57739C15.9886 8.20798 17.0136 6.99073 18.1924 5.87491C21.8823 2.37531 26.7511 0.447998 31.8761 0.447998C36.0273 0.447998 40.0248 1.71597 43.4074 4.09975C46.4311 6.28066 48.7886 9.22235 50.1723 12.6205C50.4798 13.3813 50.121 14.2943 49.3523 14.5986C49.1473 14.6999 48.9423 14.7507 48.7373 14.7507Z"
        fill="white"
      />
      <path
        d="M50.9937 11.606C43.2037 11.606 36.8999 17.8951 36.8999 25.5537C36.8999 26.4159 37.5662 27.0753 38.4375 27.0753C39.3087 27.0753 39.9749 26.4159 39.9749 25.5537C39.9749 19.5688 44.8949 14.6491 50.9937 14.6491C57.0925 14.6491 62.0125 19.5688 62.0125 25.5537C62.0125 31.5385 57.0412 36.4582 50.9937 36.4582H41.1024H17.2713H12.3C7.22624 36.4582 3.075 32.35 3.075 27.3288C3.075 22.3076 7.22624 18.1994 12.3 18.1994C17.3737 18.1994 21.525 22.3076 21.525 27.3288C21.525 28.1911 22.1912 28.8504 23.0625 28.8504C23.9337 28.8504 24.6 28.1911 24.6 27.3288C24.6 20.634 19.1162 15.1563 12.3 15.1563C5.48375 15.1563 0 20.6847 0 27.3796C0 34.0744 5.48375 39.5521 12.3 39.5521H17.3224H41.1024H50.9937C58.7836 39.5521 65.0874 33.3136 65.0874 25.6044C65.0874 17.8951 58.7836 11.606 50.9937 11.606Z"
        fill="white"
      />
      <path
        d="M39.8735 31.4371H12.301C10.0459 31.4371 8.20097 29.6113 8.20097 27.3797C8.20097 25.148 10.0459 23.3222 12.301 23.3222C14.556 23.3222 16.4009 25.148 16.4009 27.3797C16.4009 28.2419 17.0672 28.9012 17.9385 28.9012C18.8097 28.9012 19.4759 28.2419 19.4759 27.3797C19.4759 23.4743 16.2472 20.2791 12.301 20.2791C8.35472 20.2791 5.12598 23.4743 5.12598 27.3797C5.12598 31.285 8.35472 34.4803 12.301 34.4803H39.8735C40.7447 34.4803 41.4109 33.8209 41.4109 32.9587C41.4109 32.0965 40.6934 31.4371 39.8735 31.4371Z"
        fill="#F01937"
      />
      <path
        d="M50.9905 16.5772C48.9917 16.5772 47.198 17.2365 45.7117 18.3016C45.6093 17.5916 45.4554 16.78 45.1993 15.9685C44.1742 12.7226 41.9705 9.98372 38.998 8.20856C36.8455 6.9406 34.3855 6.28125 31.8742 6.28125C28.4405 6.28125 25.1093 7.54921 22.5468 9.83156C21.3168 10.8967 20.343 12.1646 19.5742 13.6354C19.1643 14.3962 19.4718 15.3092 20.1893 15.715C20.958 16.1207 21.8806 15.8164 22.2905 15.1063C22.9055 13.9905 23.6743 12.9761 24.648 12.1646C26.6467 10.3895 29.2092 9.42581 31.9255 9.42581C33.873 9.42581 35.8205 9.93301 37.5117 10.9473C39.8179 12.3168 41.5605 14.447 42.3293 16.983C42.7392 18.2509 42.8418 19.4174 42.8418 20.1782C42.8418 20.9897 42.7392 21.8012 42.5342 22.6127C42.5342 22.6635 42.5342 22.7141 42.5342 22.7648C42.2267 23.6778 42.0217 24.6414 42.0217 25.6559C42.0217 26.518 42.688 27.1774 43.5593 27.1774C44.4305 27.1774 45.0967 26.518 45.0967 25.6559C45.0967 22.4098 47.7618 19.7217 51.093 19.7217C54.4242 19.7217 57.0892 22.3591 57.0892 25.6559C57.0892 28.9526 54.3217 31.4377 50.9905 31.4377C50.1193 31.4377 49.453 32.0972 49.453 32.9593C49.453 33.8215 50.1193 34.4809 50.9905 34.4809C55.9617 34.4809 60.0617 30.4741 60.0617 25.5037C60.0617 20.5332 56.013 16.5772 50.9905 16.5772Z"
        fill="#F01937"
      />
      <path
        d="M50.9968 34.4809H33.8793C33.008 34.4809 32.3418 33.8216 32.3418 32.9593C32.3418 32.0972 33.008 31.4377 33.8793 31.4377H50.9968C51.868 31.4377 52.5342 32.0972 52.5342 32.9593C52.5342 33.8216 51.868 34.4809 50.9968 34.4809Z"
        fill="#F01937"
      />
      <path
        d="M82.9248 25.6044C82.9248 20.786 86.6661 16.7793 91.8935 16.7793C97.1723 16.7793 100.811 20.7354 100.811 25.5029V25.5537C100.811 30.3212 97.0698 34.328 91.8424 34.328C86.6149 34.328 82.9248 30.3719 82.9248 25.6044ZM97.6848 25.6044C97.6848 22.257 95.2761 19.5181 91.8424 19.5181C88.4598 19.5181 86.0511 22.2062 86.0511 25.5029V25.5537C86.0511 28.8504 88.4598 31.5385 91.8935 31.5385C95.3273 31.5892 97.6848 28.9011 97.6848 25.6044Z"
        fill="white"
      />
      <path
        d="M102.092 32.1484C101.732 31.8948 101.527 31.489 101.527 31.0325C101.527 30.2717 102.143 29.6631 102.962 29.6631C103.372 29.6631 103.629 29.7646 103.833 29.9167C105.268 31.0325 106.755 31.6412 108.652 31.6412C110.496 31.6412 111.675 30.7789 111.675 29.5109V29.4603C111.675 28.243 111.008 27.5837 107.831 26.8736C104.193 26.0114 102.143 24.9462 102.143 21.8018V21.751C102.143 18.8601 104.602 16.8313 107.985 16.8313C110.137 16.8313 111.879 17.3892 113.417 18.4036C113.776 18.6064 114.084 19.0122 114.084 19.5701C114.084 20.3309 113.469 20.9395 112.649 20.9395C112.341 20.9395 112.136 20.8888 111.879 20.7367C110.547 19.8744 109.266 19.4687 107.933 19.4687C106.191 19.4687 105.166 20.3817 105.166 21.4975V21.5481C105.166 22.8668 105.934 23.4247 109.266 24.1855C112.905 25.0477 114.75 26.3157 114.75 29.1559V29.2066C114.75 32.402 112.239 34.2786 108.652 34.2786C106.191 34.2786 103.987 33.5685 102.092 32.1484Z"
        fill="#F01937"
      />
      <path
        d="M115.574 25.6044C115.574 20.7354 119.212 16.7793 124.389 16.7793C127.054 16.7793 128.745 17.5401 130.282 18.7066C130.591 18.9602 130.846 19.3659 130.846 19.8732C130.846 20.6847 130.18 21.344 129.36 21.344C128.95 21.344 128.643 21.1918 128.437 21.0397C127.259 20.076 125.978 19.5181 124.338 19.5181C121.109 19.5181 118.701 22.1555 118.701 25.5029V25.5537C118.701 28.8504 121.058 31.5385 124.338 31.5385C126.132 31.5385 127.362 30.9806 128.643 29.9154C128.848 29.7126 129.156 29.5604 129.565 29.5604C130.334 29.5604 131 30.1691 131 30.9299C131 31.3864 130.795 31.7414 130.488 31.9949C128.899 33.415 127.054 34.2773 124.286 34.2773C119.316 34.328 115.574 30.4734 115.574 25.6044Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
