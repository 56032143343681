import React from "react";

const RegisterIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FF405B" />
      <path
        d="M14.414 24L24.556 13.858L23.142 12.444L13 22.586V24H14.414ZM15.243 26H11V21.757L22.435 10.322C22.6225 10.1345 22.8768 10.0292 23.142 10.0292C23.4072 10.0292 23.6615 10.1345 23.849 10.322L26.678 13.151C26.8655 13.3385 26.9708 13.5928 26.9708 13.858C26.9708 14.1231 26.8655 14.3774 26.678 14.565L15.243 26ZM11 28H29V30H11V28Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    </svg>
  );
};

export default RegisterIcon;
