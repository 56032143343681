import React from "react";

const TradeSupply = ({ width = 39, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_792_1971)">
        <path
          d="M16.5264 12.8145C16.5264 15.0791 16.5374 17.3326 16.5153 19.5972C16.5153 19.9507 16.6147 20.0169 16.9461 20.0169C21.4421 20.0059 25.9271 20.0059 30.4231 20.0059C31.0859 20.0059 31.1853 20.1164 31.1742 20.7792C31.1632 21.7734 31.0638 22.7565 30.8208 23.7176C30.7324 24.0821 30.8097 24.1705 31.1632 24.2147C33.1626 24.4577 34.8417 25.3304 36.1342 26.8659C38.1337 29.2409 38.6639 31.9473 37.5813 34.8526C36.4877 37.7689 34.3115 39.5033 31.2184 39.9341C27.3742 40.4754 23.8393 38.2219 22.6241 34.5212C22.5247 34.2119 22.4032 34.234 22.1712 34.3445C20.1276 35.2613 17.9845 35.7253 15.7641 35.6259C9.58906 35.3387 5.01574 32.4334 2.34244 26.8659C-0.551787 20.8454 0.895327 13.875 5.789 9.32375C8.16404 7.11442 10.992 5.833 14.2176 5.43532C14.7699 5.36904 15.3112 5.358 15.8636 5.3359C16.3717 5.31381 16.5264 5.47951 16.5264 5.98766C16.5264 8.27432 16.5264 10.5499 16.5264 12.8145ZM15.5322 13.5215C15.5322 11.2459 15.5211 8.98131 15.5432 6.70569C15.5432 6.40743 15.4659 6.34115 15.1787 6.36324C13.8973 6.46266 12.649 6.69464 11.4339 7.11442C5.34714 9.21329 1.44766 15.1785 1.95581 21.5966C2.22093 24.8996 3.48025 27.7607 5.75586 30.1688C8.2745 32.8311 11.3897 34.3113 15.0572 34.5875C17.4764 34.7642 19.7851 34.3334 21.9945 33.3282C22.1712 33.2509 22.3038 33.1956 22.2706 32.9194C22.0166 30.0142 23.0329 27.6391 25.3085 25.8164C26.4352 24.9106 27.7387 24.3693 29.1858 24.2368C29.6056 24.1926 29.7603 24.0269 29.8376 23.6292C29.9702 22.9001 30.0696 22.16 30.158 21.4309C30.2021 21.0885 30.1138 21.0001 29.7603 21.0001C25.2864 21.0111 20.8125 21.0111 16.3386 21.0111C15.6647 21.0111 15.5432 20.8896 15.5432 20.2268C15.5322 17.9843 15.5322 15.7529 15.5322 13.5215ZM30.158 25.1315C26.3689 25.1315 23.2317 28.2798 23.2427 32.0799C23.2538 35.9021 26.3579 39.0062 30.169 39.0172C33.958 39.0283 37.0953 35.891 37.1063 32.0909C37.1174 28.2688 33.9801 25.1315 30.158 25.1315Z"
          fill="#1740A8"
        />
        <path
          d="M20.9893 7.83209C20.9893 5.44601 20.9893 3.07097 20.9893 0.684894C20.9893 0.143607 21.1329 0 21.6742 0C28.6225 0.0110467 34.8308 5.09252 36.2779 11.9304C36.4878 12.9246 36.6203 13.9409 36.6203 14.9572C36.6203 15.4764 36.4767 15.62 35.9686 15.62C31.1853 15.62 26.4132 15.62 21.63 15.62C21.1439 15.62 21.0003 15.4764 21.0003 14.9793C20.9893 12.5932 20.9893 10.2182 20.9893 7.83209ZM22.6463 14.6258C24.3254 14.6258 25.9382 14.6258 27.5399 14.6258C27.6725 14.6258 27.7388 14.5595 27.8161 14.4822C29.7935 12.5048 31.7708 10.5385 33.7482 8.56117C33.8697 8.43966 33.8807 8.36233 33.8034 8.20768C33.2842 7.30185 32.6766 6.45126 31.9918 5.6559C31.8592 5.50124 31.7708 5.47915 31.6051 5.64485C28.6446 8.62745 25.6841 11.599 22.6463 14.6258ZM21.9835 13.8746C25.0103 10.8478 27.9597 7.89837 30.9313 4.94891C31.1191 4.76112 31.0196 4.69484 30.8871 4.57332C30.1249 3.91052 29.3074 3.33609 28.4458 2.82795C28.2359 2.70643 28.1033 2.71748 27.9266 2.89423C26.0155 4.81635 24.0934 6.73847 22.1823 8.64954C22.0829 8.74896 21.9945 8.82629 21.9945 8.99199C21.9835 10.5938 21.9835 12.1845 21.9835 13.8746ZM29.208 14.6258C31.2627 14.6258 33.3394 14.6258 35.4052 14.6258C35.6261 14.6258 35.604 14.5153 35.5819 14.3717C35.5267 13.7642 35.4825 13.1676 35.361 12.5711C35.1511 11.4775 34.8418 10.417 34.3889 9.43386C32.6656 11.1682 30.9423 12.8915 29.208 14.6258ZM21.9835 7.26871C23.6846 5.57857 25.3858 3.86634 27.087 2.16515C25.5515 1.48025 23.9056 1.12676 22.1933 1.00525C21.8951 0.983154 21.9835 1.18199 21.9835 1.31455C21.9835 3.314 21.9835 5.31345 21.9835 7.26871Z"
          fill="#FF405B"
        />
        <path
          d="M29.2579 33.3408V34.1278H31.3082V34.8084H29.2579V35.8639H28.4846V34.8084H27.833V34.1278H28.4846V33.3408H27.833V32.6139H28.4846V29.1973H30.8261C30.9795 29.1973 31.133 29.2096 31.2865 29.2343C31.6167 29.2899 31.8976 29.4133 32.1293 29.6047C32.361 29.7929 32.5362 30.0321 32.6549 30.3223C32.7736 30.6124 32.833 30.9365 32.833 31.2945C32.833 31.8346 32.6998 32.2837 32.4334 32.6417C32.1669 32.9997 31.7846 33.2204 31.2865 33.3037C31.133 33.3284 30.9795 33.3408 30.8261 33.3408H29.2579ZM29.2579 29.9797V32.6139H30.8C30.9477 32.6139 31.078 32.5985 31.191 32.5676C31.469 32.4997 31.6804 32.3531 31.8252 32.1278C31.97 31.8994 32.0424 31.6278 32.0424 31.313C32.0424 31.0044 31.9714 30.7297 31.8295 30.4889C31.6876 30.2451 31.4748 30.0892 31.191 30.0213C31.0838 29.9936 30.9535 29.9797 30.8 29.9797H29.2579Z"
          fill="#FF405B"
        />
      </g>
      <defs>
        <clipPath id="clip0_792_1971">
          <rect
            width="37.2715"
            height="40"
            fill="white"
            transform="translate(0.864258)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TradeSupply;
