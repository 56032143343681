export const switches = [
  {
    id: 1,
    title: "Бухгалтерия\nПРОФ",
    web: 1230,
    rdp: 1780,
  },
  {
    id: 2,
    title: "Бухгалтерия\nКОРП",
    web: 1630,
    rdp: 2180,
  },
  {
    id: 3,
    title: "Зарплата и Управление персоналом",
    web: 1330,
    rdp: 1880,
  },
  {
    id: 4,
    title: "Управление торговлей",
    web: 1330,
    rdp: 1880,
  },
  {
    id: 5,
    title: "Розница\nПРОФ",
    web: 1230,
    rdp: 1780,
  },
  {
    id: 6,
    title: "Управление нашей фирмой",
    web: 1230,
    rdp: 1780,
  },
  {
    id: 7,
    title: "Комплексная автоматизация",
    web: 1860,
    rdp: 2410,
  },

  {
    id: 8,
    title: "Автосервис",
    web: 1330,
    rdp: 1880,
  },
];
