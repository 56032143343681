import React from "react";

const ZoomInIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.531 14.617L20.814 18.899L19.399 20.314L15.117 16.031C13.5237 17.3082 11.542 18.0029 9.5 18C4.532 18 0.5 13.968 0.5 9C0.5 4.032 4.532 0 9.5 0C14.468 0 18.5 4.032 18.5 9C18.5029 11.042 17.8082 13.0237 16.531 14.617ZM14.525 13.875C15.7941 12.5699 16.5029 10.8204 16.5 9C16.5 5.132 13.367 2 9.5 2C5.632 2 2.5 5.132 2.5 9C2.5 12.867 5.632 16 9.5 16C11.3204 16.0029 13.0699 15.2941 14.375 14.025L14.525 13.875ZM8.5 8V5H10.5V8H13.5V10H10.5V13H8.5V10H5.5V8H8.5Z"
        fill="#FF405B"
      />
    </svg>
  );
};

export default ZoomInIcon;
