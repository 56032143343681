import React from "react";

const YoutubeWithCircle = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_792_1649)">
        <path
          d="M14.4842 12.2717L11.6767 10.9617C11.4317 10.848 11.2305 10.9755 11.2305 11.2467V13.7142C11.2305 13.9855 11.4317 14.113 11.6767 13.9992L14.483 12.6892C14.7292 12.5742 14.7292 12.3867 14.4842 12.2717ZM12.4805 0.480469C5.85297 0.480469 0.480469 5.85297 0.480469 12.4805C0.480469 19.108 5.85297 24.4805 12.4805 24.4805C19.108 24.4805 24.4805 19.108 24.4805 12.4805C24.4805 5.85297 19.108 0.480469 12.4805 0.480469ZM12.4805 17.3555C6.33797 17.3555 6.23047 16.8017 6.23047 12.4805C6.23047 8.15922 6.33797 7.60547 12.4805 7.60547C18.623 7.60547 18.7305 8.15922 18.7305 12.4805C18.7305 16.8017 18.623 17.3555 12.4805 17.3555Z"
          fill="#4399FF33"
        />
      </g>
      <defs>
        <clipPath id="clip0_792_1649">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeWithCircle;
