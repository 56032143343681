import React from "react";

const QuicklyIcon = ({ width = 33, height = 56 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2287 11.282C18.0463 14.6119 17.879 17.9418 17.6966 21.2717C17.6509 22.1536 17.6509 22.1536 18.5024 22.1536C23.0031 22.1688 27.4886 22.184 31.9893 22.1992C32.9168 22.1992 33.1752 22.6402 32.7495 23.4612C27.1693 34.0895 21.6042 44.7026 16.024 55.3309C15.9784 55.4069 15.9328 55.4981 15.8872 55.5742C15.7047 55.9239 15.4006 56.0607 15.0357 55.9543C14.686 55.8479 14.5491 55.559 14.5643 55.194C14.6708 53.0349 14.7772 50.8758 14.8836 48.7167C15.0509 45.3716 15.2333 42.0113 15.4158 38.6662C15.4918 37.2826 15.5374 35.8989 15.6439 34.5153C15.6895 33.9983 15.5831 33.8158 15.0053 33.8158C10.459 33.831 5.89748 33.8006 1.35119 33.7854C0.393272 33.7854 0.134787 33.3597 0.590937 32.493C6.17117 21.8951 11.7514 11.2668 17.3164 0.653735C17.4077 0.48648 17.4989 0.30402 17.6205 0.18238C17.8334 -0.0152848 18.0919 -0.0456948 18.3656 0.0759452C18.7001 0.2432 18.7761 0.532095 18.7609 0.88181C18.5937 3.9076 18.4416 6.9334 18.2896 9.9744C18.2743 10.4153 18.2896 10.8411 18.2896 11.282C18.2743 11.282 18.2439 11.282 18.2287 11.282ZM2.18746 32.4474C6.8706 32.4474 11.4625 32.4474 16.0392 32.4474C16.8907 32.4474 17.1188 32.7211 17.0732 33.5421C16.9971 34.6977 16.9515 35.8533 16.8907 37.0089C16.7082 40.3844 16.541 43.7447 16.3585 47.1202C16.2825 48.5343 16.2825 49.9483 16.1 51.3624C16.0848 51.484 15.9936 51.6969 16.1913 51.7577C16.3889 51.8033 16.4041 51.5905 16.4802 51.4688C16.5258 51.408 16.5562 51.332 16.5866 51.2712C21.3306 42.2242 26.0897 33.1772 30.8337 24.1302C30.9249 23.963 31.077 23.7957 31.0465 23.5677C30.8337 23.5677 30.6208 23.5677 30.4079 23.5677C26.0289 23.5677 21.6499 23.5677 17.2708 23.5525C16.465 23.5525 16.2065 23.2788 16.2369 22.5641C16.5258 17.0903 16.8147 11.6317 17.1036 6.15794C17.134 5.53454 17.1796 4.89593 17.0579 4.10527C12.0707 13.6084 7.15949 22.9899 2.18746 32.4474Z"
        fill="#1740A8"
      />
      <path
        d="M22.6233 35.1386C22.1671 35.1234 21.8326 34.6825 21.9694 34.2567C22.0607 33.9983 22.2127 33.7398 22.3344 33.4965C23.2467 31.7175 24.1437 29.9385 25.056 28.1595C25.1169 28.0379 25.1625 27.9163 25.2385 27.8098C25.4666 27.4753 25.7707 27.3081 26.1508 27.5361C26.5157 27.749 26.6526 28.0531 26.4549 28.4484C25.3906 30.5467 24.3262 32.645 23.2467 34.7433C23.125 35.0018 22.8817 35.1234 22.6233 35.1386Z"
        fill="#FF405B"
      />
      <path
        d="M21.5576 37.9366C21.5424 37.9974 21.5119 38.1191 21.4663 38.2103C21.1622 38.8185 20.8581 39.4267 20.5388 40.0197C20.3412 40.3998 20.0067 40.5063 19.6265 40.3238C19.2312 40.1413 19.14 39.7764 19.3072 39.4115C19.5961 38.7729 19.9306 38.1647 20.2651 37.5565C20.4476 37.2372 20.7517 37.1307 21.0862 37.2372C21.3751 37.3436 21.5424 37.5717 21.5576 37.9366Z"
        fill="#FF405B"
      />
    </svg>
  );
};

export default QuicklyIcon;
