import React from "react";

const PlugIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1249 20.625L24.1561 29.5937C23.693 30.0586 23.1427 30.4275 22.5367 30.6792C21.9307 30.9309 21.281 31.0604 20.6249 31.0604C19.9687 31.0604 19.319 30.9309 18.713 30.6792C18.107 30.4275 17.5567 30.0586 17.0936 29.5937L10.4061 22.9063C9.94125 22.4432 9.57239 21.8928 9.3207 21.2869C9.06901 20.6809 8.93945 20.0312 8.93945 19.375C8.93945 18.7188 9.06901 18.0691 9.3207 17.4631C9.57239 16.8572 9.94125 16.3068 10.4061 15.8438L19.3749 6.875"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 26.25L5 35"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 10L28.75 3.75"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.25 11.25L30 17.5"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 22.5L17.5 5"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlugIcon;
