import React from "react";

const Key = ({ width = 22, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.917 7.99999C11.6623 9.48006 10.8619 10.8108 9.67392 11.7296C8.48593 12.6484 6.99666 13.0885 5.50009 12.9629C4.00352 12.8373 2.60841 12.1552 1.59016 11.0513C0.57191 9.94738 0.00452288 8.50182 2.5002e-06 6.99999C-0.00137098 5.4946 0.563193 4.0437 1.58168 2.93515C2.60017 1.8266 3.99816 1.14141 5.49828 1.01553C6.9984 0.889652 8.49103 1.33228 9.68002 2.25559C10.869 3.1789 11.6675 4.51543 11.917 5.99999H22V7.99999H20V12H18V7.99999H16V12H14V7.99999H11.917ZM6 11C7.06087 11 8.07828 10.5786 8.82843 9.82842C9.57858 9.07827 10 8.06086 10 6.99999C10 5.93913 9.57858 4.92171 8.82843 4.17157C8.07828 3.42142 7.06087 2.99999 6 2.99999C4.93914 2.99999 3.92172 3.42142 3.17158 4.17157C2.42143 4.92171 2 5.93913 2 6.99999C2 8.06086 2.42143 9.07827 3.17158 9.82842C3.92172 10.5786 4.93914 11 6 11Z"
        fill="white"
      />
    </svg>
  );
};

export default Key;
