import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1701_5707)">
        <path
          d="M11.6666 11.8766V13.6183C10.9124 13.3516 10.1051 13.2698 9.31276 13.3797C8.52036 13.4897 7.76592 13.7882 7.11278 14.2501C6.45964 14.712 5.92687 15.3239 5.55922 16.0344C5.19157 16.7449 4.99977 17.5333 4.99992 18.3333L3.33325 18.3324C3.33299 17.3148 3.56569 16.3107 4.0135 15.3969C4.46132 14.4832 5.11237 13.684 5.91677 13.0608C6.72117 12.4375 7.65757 12.0067 8.65421 11.8013C9.65086 11.5959 10.6813 11.6214 11.6666 11.8758V11.8766ZM9.99992 10.8333C7.23742 10.8333 4.99992 8.59575 4.99992 5.83325C4.99992 3.07075 7.23742 0.833252 9.99992 0.833252C12.7624 0.833252 14.9999 3.07075 14.9999 5.83325C14.9999 8.59575 12.7624 10.8333 9.99992 10.8333ZM9.99992 9.16658C11.8416 9.16658 13.3333 7.67492 13.3333 5.83325C13.3333 3.99159 11.8416 2.49992 9.99992 2.49992C8.15825 2.49992 6.66659 3.99159 6.66659 5.83325C6.66659 7.67492 8.15825 9.16658 9.99992 9.16658ZM15.4883 14.1666L13.9641 12.6433L15.1433 11.4641L18.6783 14.9999L15.1433 18.5358L13.9641 17.3566L15.4883 15.8333H12.4999V14.1666H15.4883Z"
          fill="#FF405B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1701_5707">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
