import React from "react";

const QuestionMark = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#9AACC0" />
      <path
        d="M10.709 14.693C10.709 14.279 10.748 13.88 10.826 13.496C10.91 13.106 11.045 12.746 11.231 12.416C11.393 12.098 11.573 11.846 11.771 11.66C11.969 11.468 12.191 11.279 12.437 11.093C12.683 10.901 12.956 10.646 13.256 10.328C13.502 10.058 13.664 9.791 13.742 9.527C13.82 9.257 13.859 8.969 13.859 8.663C13.859 8.465 13.838 8.279 13.796 8.105C13.754 7.931 13.679 7.781 13.571 7.655C13.403 7.433 13.187 7.277 12.923 7.187C12.659 7.091 12.377 7.043 12.077 7.043C11.831 7.043 11.594 7.076 11.366 7.142C11.144 7.202 10.955 7.298 10.799 7.43C10.619 7.574 10.487 7.751 10.403 7.961C10.319 8.171 10.28 8.402 10.286 8.654H8C8.06 8.054 8.222 7.499 8.486 6.989C8.756 6.473 9.11 6.062 9.548 5.756C9.89 5.504 10.274 5.315 10.7 5.189C11.132 5.063 11.564 5 11.996 5C12.656 5 13.277 5.102 13.859 5.306C14.447 5.51 14.936 5.846 15.326 6.314C15.596 6.62 15.8 6.974 15.938 7.376C16.082 7.778 16.154 8.186 16.154 8.6C16.154 9.218 16.019 9.782 15.749 10.292C15.485 10.802 15.149 11.273 14.741 11.705C14.549 11.909 14.366 12.089 14.192 12.245C14.018 12.401 13.856 12.554 13.706 12.704C13.562 12.848 13.442 13.001 13.346 13.163C13.178 13.445 13.079 13.685 13.049 13.883C13.019 14.075 13.004 14.345 13.004 14.693H10.709ZM10.727 18.212V15.827H13.004V18.212H10.727Z"
        fill="white"
      />
    </svg>
  );
};

export default QuestionMark;
