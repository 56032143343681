import React from "react";

const Cash = ({ width = 41, height = 40, color = "#FF405B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1641 21.6667C12.1641 18.524 12.1641 16.9527 13.1404 15.9763C14.1167 15 15.688 15 18.8307 15H23.8307H28.8307C31.9734 15 33.5447 15 34.5211 15.9763C35.4974 16.9527 35.4974 18.524 35.4974 21.6667V23.3333V25C35.4974 28.1427 35.4974 29.714 34.5211 30.6903C33.5447 31.6667 31.9734 31.6667 28.8307 31.6667H23.8307H18.8307C15.688 31.6667 14.1167 31.6667 13.1404 30.6903C12.1641 29.714 12.1641 28.1427 12.1641 25V23.3333V21.6667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 25.0007C9.02397 25.0007 7.45262 25.0007 6.47632 24.0243C5.5 23.048 5.5 21.4767 5.5 18.334V15.0007C5.5 11.858 5.5 10.2866 6.47632 9.3103C7.45262 8.33398 9.02397 8.33398 12.1667 8.33398H22.1667C25.3093 8.33398 26.8807 8.33398 27.857 9.3103C28.8333 10.2866 28.8333 11.858 28.8333 15.0007"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1667 23.3333C27.1667 25.1743 25.6743 26.6667 23.8333 26.6667C21.9923 26.6667 20.5 25.1743 20.5 23.3333C20.5 21.4923 21.9923 20 23.8333 20C25.6743 20 27.1667 21.4923 27.1667 23.3333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Cash;
