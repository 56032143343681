import React from "react";

const VkWithCircle = ({ width = 24, height = 24, color = "#4399FF33" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_792_1647)">
        <path
          d="M12 0C5.37249 0 0 5.37249 0 12C0 18.6275 5.37249 24 12 24C18.6275 24 24 18.6275 24 12C24 5.37249 18.6275 0 12 0ZM16.615 13.5387C16.615 13.5387 17.6762 14.5862 17.9375 15.0725C17.945 15.0825 17.9487 15.0925 17.9512 15.0975C18.0575 15.2762 18.0825 15.415 18.03 15.5187C17.9425 15.6912 17.6425 15.7762 17.54 15.7837H15.665C15.535 15.7837 15.2625 15.75 14.9325 15.5225C14.6787 15.345 14.4287 15.0537 14.185 14.77C13.8212 14.3475 13.5062 13.9825 13.1887 13.9825C13.1484 13.9824 13.1083 13.9887 13.07 14.0012C12.83 14.0787 12.5225 14.4212 12.5225 15.3337C12.5225 15.6187 12.2975 15.7825 12.1387 15.7825H11.28C10.9875 15.7825 9.46373 15.68 8.11374 14.2562C6.46124 12.5125 4.97374 9.01499 4.96124 8.98249C4.86749 8.75624 5.06124 8.63499 5.27249 8.63499H7.16624C7.41874 8.63499 7.50124 8.78874 7.55874 8.92499C7.62624 9.08374 7.87374 9.71498 8.27999 10.425C8.93874 11.5825 9.34249 12.0525 9.66623 12.0525C9.72694 12.0518 9.78656 12.0363 9.83998 12.0075C10.2625 11.7725 10.1837 10.2662 10.165 9.95373C10.165 9.89498 10.1637 9.27998 9.94748 8.98499C9.79248 8.77124 9.52873 8.68999 9.36873 8.65999C9.43349 8.57063 9.51881 8.49817 9.61748 8.44874C9.90748 8.30374 10.43 8.28249 10.9487 8.28249H11.2375C11.8 8.28999 11.945 8.32624 12.1487 8.37749C12.5612 8.47624 12.57 8.74249 12.5337 9.65373C12.5225 9.91248 12.5112 10.205 12.5112 10.55C12.5112 10.625 12.5075 10.705 12.5075 10.79C12.495 11.2537 12.48 11.78 12.8075 11.9962C12.8502 12.023 12.8996 12.0373 12.95 12.0375C13.0637 12.0375 13.4062 12.0375 14.3337 10.4462C14.6198 9.93406 14.8683 9.40183 15.0775 8.85374C15.0962 8.82124 15.1512 8.72124 15.2162 8.68249C15.2642 8.65803 15.3174 8.64559 15.3712 8.64624H17.5975C17.84 8.64624 18.0062 8.68249 18.0375 8.77624C18.0925 8.92499 18.0275 9.37873 17.0112 10.755L16.5575 11.3537C15.6362 12.5612 15.6362 12.6225 16.615 13.5387V13.5387Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_792_1647">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VkWithCircle;
