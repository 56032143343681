import React from "react";

const SmallCloudIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FF405B" />
      <path
        d="M25.0001 29H15.0001C13.5833 29.0009 12.212 28.5004 11.1288 27.5871C10.0456 26.6739 9.32059 25.4068 9.08202 24.0103C8.84346 22.6137 9.10678 21.1778 9.82535 19.9568C10.5439 18.7358 11.6714 17.8084 13.0081 17.339C12.9628 16.3926 13.1101 15.4469 13.4411 14.5592C13.772 13.6715 14.2797 12.8601 14.9335 12.1744C15.5872 11.4886 16.3733 10.9427 17.2442 10.5697C18.1151 10.1967 19.0527 10.0044 20.0001 10.0044C20.9475 10.0044 21.8851 10.1967 22.756 10.5697C23.6269 10.9427 24.413 11.4886 25.0668 12.1744C25.7205 12.8601 26.2282 13.6715 26.5592 14.5592C26.8901 15.4469 27.0374 16.3926 26.9921 17.339C28.3288 17.8084 29.4563 18.7358 30.1749 19.9568C30.8935 21.1778 31.1568 22.6137 30.9182 24.0103C30.6796 25.4068 29.9546 26.6739 28.8714 27.5871C27.7883 28.5004 26.4169 29.0009 25.0001 29ZM25.0001 17C25.0004 16.333 24.8672 15.6727 24.6083 15.0579C24.3495 14.4432 23.9703 13.8865 23.4931 13.4205C23.0158 12.9545 22.4502 12.5888 21.8294 12.3448C21.2087 12.1007 20.5454 11.9834 19.8786 11.9996C19.2118 12.0158 18.555 12.1652 17.9468 12.4392C17.3387 12.7131 16.7914 13.1059 16.3374 13.5945C15.8834 14.0831 15.5317 14.6576 15.303 15.2842C15.0744 15.9108 14.9735 16.5768 15.0061 17.243L15.0761 18.731L13.6721 19.225C12.7826 19.5395 12.0329 20.1583 11.5552 20.9719C11.0776 21.7855 10.9027 22.7418 11.0616 23.6718C11.2204 24.6018 11.7027 25.4458 12.4234 26.0547C13.144 26.6637 14.0566 26.9985 15.0001 27H25.0001C25.7365 26.9997 26.4584 26.7962 27.0865 26.4119C27.7146 26.0276 28.2244 25.4774 28.5598 24.8218C28.8952 24.1663 29.0431 23.4309 28.9873 22.6967C28.9315 21.9625 28.6741 21.2579 28.2434 20.6606C27.8128 20.0633 27.2257 19.5965 26.5467 19.3116C25.8677 19.0266 25.1232 18.9347 24.3953 19.0457C23.6674 19.1568 22.9843 19.4667 22.4212 19.9412C21.8582 20.4157 21.437 21.0364 21.2041 21.735L19.3061 21.102C19.7049 19.9073 20.4694 18.8684 21.4913 18.1323C22.5132 17.3961 23.7407 17 25.0001 17Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    </svg>
  );
};

export default SmallCloudIcon;
