import clsx from "clsx";
import React from "react";
import Statuses from "../../assets/icons/Statuses";
import Accordion from "../../common/accordion/Accordion";
import styles from "./style.module.css";

const data = [
  "Расчет заработанной платы",
  "Исчисление регламентированных законодательством налогов и взносов с фонда оплаты труда",
  "Отражение начисленной зарплаты и налогов в затратах предприятия",
  "Управление денежными расчетами с персоналом, включая депонирование",
  "Учет кадров и анализ кадрового состава",
  "Автоматизация кадрового делопроизводства",
  "Управление компетенциями и аттестация работников",
  "Возможность сдачи отчетов по каждому из сотрудников",
];

const SalaryPersonnelManagement = () => {
  return (
    <Accordion title="1С: Зарплата и управление персоналом в Облаке ">
      <p className={clsx(styles.accordionDescription, styles.mb30)}>
        «1С:Зарплата и управление персоналом 8» – программа массового
        назначения, позволяющая в комплексе автоматизировать задачи, связанные с
        расчетом заработной платы персонала и реализацией кадровой политики, с
        учетом требований законодательства и реальной практики работы
        предприятий.
      </p>
      <p className={clsx(styles.accordionDescription, styles.mb40)}>
        Она может успешно применяться в службах управления персоналом и
        бухгалтериях предприятий, а также в других подразделениях,
        заинтересованных в эффективной организации работы сотрудников, для
        управления человеческими ресурсами коммерческих предприятий различного
        масштаба.
      </p>
      <p className={styles.accordionTitle}>
        Основные возможности 1С: Зарплата и управление персоналом в Облаке
      </p>
      <div className={styles.accordionList}>
        <ul>
          {data.map((item) => {
            return (
              <li key={Math.random()}>
                <Statuses />
                <p>{item}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </Accordion>
  );
};

export default SalaryPersonnelManagement;
