import React from "react";

const ThumbsUpIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 16.25H12.5V32.5H5C4.66848 32.5 4.35054 32.3683 4.11612 32.1339C3.8817 31.8995 3.75 31.5815 3.75 31.25V17.5C3.75 17.1685 3.8817 16.8505 4.11612 16.6161C4.35054 16.3817 4.66848 16.25 5 16.25V16.25Z"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.25L18.75 3.75C20.0761 3.75 21.3479 4.27678 22.2855 5.21447C23.2232 6.15215 23.75 7.42392 23.75 8.75V12.5H33.4219C33.7764 12.4991 34.1269 12.5741 34.45 12.7199C34.7731 12.8658 35.0613 13.0791 35.2951 13.3455C35.529 13.6119 35.7031 13.9252 35.8058 14.2645C35.9085 14.6038 35.9374 14.9611 35.8906 15.3125L34.0156 30.3125C33.9398 30.9147 33.6474 31.4687 33.1932 31.8712C32.7389 32.2737 32.1538 32.4972 31.5469 32.5H12.5"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbsUpIcon;
