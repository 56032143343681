import React from "react";

const FacebookWithCircle = ({
  width = 24,
  height = 24,
  color = "#4399FF33",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.3725 0 0 5.3725 0 12C0 18.6275 5.3725 24 12 24C18.6275 24 24 18.6275 24 12C24 5.3725 18.6275 0 12 0ZM14.8425 8.2925H13.0388C12.825 8.2925 12.5875 8.57375 12.5875 8.9475V10.25H14.8438L14.5025 12.1075H12.5875V17.6838H10.4588V12.1075H8.5275V10.25H10.4588V9.1575C10.4588 7.59 11.5463 6.31625 13.0388 6.31625H14.8425V8.2925Z"
        fill={color}
      />
    </svg>
  );
};

export default FacebookWithCircle;
