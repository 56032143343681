import React from "react";

const EmailIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FF405B" />
      <path
        d="M24.1 11C23.9659 11.6599 23.9659 12.3401 24.1 13H12.511L20.061 19.662L25.11 15.142C25.536 15.669 26.068 16.108 26.673 16.427L20.072 22.338L12 15.216V27H28V16.9C28.6599 17.0341 29.3401 17.0341 30 16.9V28C30 28.2652 29.8946 28.5196 29.7071 28.7071C29.5196 28.8946 29.2652 29 29 29H11C10.7348 29 10.4804 28.8946 10.2929 28.7071C10.1054 28.5196 10 28.2652 10 28V12C10 11.7348 10.1054 11.4804 10.2929 11.2929C10.4804 11.1054 10.7348 11 11 11H24.1ZM29 15C28.606 15 28.2159 14.9224 27.8519 14.7716C27.488 14.6209 27.1573 14.3999 26.8787 14.1213C26.6001 13.8427 26.3791 13.512 26.2284 13.1481C26.0776 12.7841 26 12.394 26 12C26 11.606 26.0776 11.2159 26.2284 10.8519C26.3791 10.488 26.6001 10.1573 26.8787 9.87868C27.1573 9.6001 27.488 9.37913 27.8519 9.22836C28.2159 9.0776 28.606 9 29 9C29.7956 9 30.5587 9.31607 31.1213 9.87868C31.6839 10.4413 32 11.2044 32 12C32 12.7956 31.6839 13.5587 31.1213 14.1213C30.5587 14.6839 29.7956 15 29 15Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" />
    </svg>
  );
};

export default EmailIcon;
