import React from "react";

const SmileIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 18.75C15.4105 18.75 16.25 17.9105 16.25 16.875C16.25 15.8395 15.4105 15 14.375 15C13.3395 15 12.5 15.8395 12.5 16.875C12.5 17.9105 13.3395 18.75 14.375 18.75Z"
        fill="#FF405B"
      />
      <path
        d="M25.625 18.75C26.6605 18.75 27.5 17.9105 27.5 16.875C27.5 15.8395 26.6605 15 25.625 15C24.5895 15 23.75 15.8395 23.75 16.875C23.75 17.9105 24.5895 18.75 25.625 18.75Z"
        fill="#FF405B"
      />
      <path
        d="M26.5 23.75C25.8392 24.8884 24.891 25.8334 23.7503 26.4902C22.6095 27.147 21.3163 27.4927 20 27.4927C18.6837 27.4927 17.3905 27.147 16.2497 26.4902C15.109 25.8334 14.1608 24.8884 13.5 23.75"
        stroke="#FF405B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmileIcon;
